import { graphql } from 'gatsby';
import React from 'react';
import PortableText from 'react-portable-text';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import '../styles/react-player.scss';
import Slider from '../components/Slider';

const SectionStyles = styled.section`
  max-width: 800px;
  margin: 0 auto;
  padding: 0 var(--padding-medium) var(--padding-medium);

  & > div:not(:last-of-type) {
    margin-bottom: var(--padding-large);
  }

  .titleContainer {
    h2 {
      margin-bottom: 0.5rem;
    }
    h3 {
      font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light',
        'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
      font-weight: 300;
      font-size: 1.6rem;
      margin-bottom: 2rem;
    }
  }
`;

export default function Project({ data }) {
  const { project } = data;

  return (
    <SectionStyles>
      {project.vimeoLink ? (
        // layout when there is a video
        <>
          <div className="player-wrapper">
            <ReactPlayer
              className="react-player"
              url={project.vimeoLink}
              controls
              width="100%"
              height="100%"
            />
          </div>
          <div className="titleContainer">
            <h2>{project.title}</h2>
            <h3>{project.subtitle}</h3>
            {project.pdf && (
              <a
                href={project.pdf.asset.url}
                className="btn"
                target="_blank"
                rel="noreferrer"
              >
                Approfondimenti
              </a>
            )}
          </div>
          <PortableText content={project._rawBody} />
          <Slider gallery={project.gallery} controlsBottom="false" />
        </>
      ) : (
        // layout when there is no video
        <>
          <Slider gallery={project.gallery} controlsBottom pos="top" />
          <div className="titleContainer">
            <h2>{project.title}</h2>
            <h3>{project.subtitle}</h3>
            {project.pdf && (
              <a
                href={project.pdf.asset.url}
                className="btn"
                target="_blank"
                rel="noreferrer"
              >
                Approfondimenti
              </a>
            )}
          </div>
          <PortableText content={project._rawBody} />
        </>
      )}
    </SectionStyles>
  );
}

export const query = graphql`
  query ($slug: String!) {
    project: sanityProject(slug: { current: { eq: $slug } }) {
      id
      title
      subtitle
      _rawBody
      pdf {
        asset {
          id
          url
        }
      }
      vimeoLink
      gallery {
        images {
          asset {
            id
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        foto {
          asset {
            id
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        disegni {
          asset {
            id
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        partiture {
          asset {
            id
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
  }
`;
