import React, { useState } from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import '../styles/swiper.scss';
import { GatsbyImage } from 'gatsby-plugin-image';
import Arrow from '../assets/images/arrow.png';

SwiperCore.use([Navigation]);

export default function Slider({ gallery, controlsBottom, pos }) {
  const types = Object.keys(gallery)
    .map((key) => ({
      name: key,
      images: gallery[key],
    }))
    .filter((item) => item.images.length > 0);

  const [galleryItems, setGalleryItems] = useState(types[0].images);
  const [activeType, setActiveType] = useState(types[0].name);

  const handleClick = (type, items) => {
    setGalleryItems(items);
    setActiveType(type);
  };

  return (
    <>
      <div
        className={`slider-controls-wrapper ${
          pos === 'top' ? 'top' : 'bottom'
        }`}
      >
        {types.length > 1 && (
          <div
            className={
              controlsBottom === true ? 'controls bottom' : 'controls top'
            }
          >
            {types.map((type) => (
              <button
                type="button"
                className={
                  type.name === activeType
                    ? 'galleryType active'
                    : 'galleryType'
                }
                key={type.name}
                onClick={() => handleClick(type.name, type.images)}
              >
                {type.name.charAt(0).toUpperCase() + type.name.slice(1)}
              </button>
            ))}
          </div>
        )}
        <div className="slider-container">
          <Swiper
            className={galleryItems.length < 2 ? 'disabled' : null}
            spaceBetween={0}
            slidesPerView={1}
            navigation={{
              prevEl: '.prev',
              nextEl: '.next',
            }}
            loop
          >
            {galleryItems.map((item, index) => (
              <SwiperSlide key={index}>
                <GatsbyImage
                  image={item.asset.gatsbyImageData}
                  objectFit="contain"
                  alt="image"
                />
              </SwiperSlide>
            ))}
          </Swiper>

          <div className={galleryItems.length > 1 ? 'nav' : 'nav hidden'}>
            <div className="prev">
              <img src={Arrow} alt="previous" />
            </div>
            <div className="next">
              <img src={Arrow} alt="next" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
